// assets
import { IconTools } from '@tabler/icons';

// constant
const icons = {
    IconTools,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'tools',
    title: 'Tools',
    type: 'group',
    children: [
        {
            id: 'tools-base64-converter',
            title: 'Base64 Converter',
            type: 'item',
            url: '/tools/base64-converter',
            icon: icons.IconTools,
            breadcrumbs: false,
        },
    ]
};

export default utilities;
