import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// utilities routing
const Base64Converter = Loadable(lazy(() => import('views/utilities/Base64Converter')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Base64Converter />
        },
        {
            path: 'tools',
            children: [
                {
                    path: 'base64-converter',
                    element: <Base64Converter />
                }
            ]
        },
    ]
};

export default MainRoutes;
