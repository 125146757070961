import logo from 'assets/images/logo.png';
// ==============================|| LOGO ||============================== //

const Logo = () => {
    return (
        <img src={logo} alt="Berry" width="170" />
    );
};

export default Logo;
